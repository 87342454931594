import { defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { type RouteLocationNormalized, useRoute, useRouter } from 'vue-router';

import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { getFinialLocale } from '@/composables/useLocale';
import { COOKIE_KEYS } from '@/constants/cookie.const';
import { GROUP_HOME_PAGE_NAME, GROUP_REGISTER_PAGE_URL } from '@/constants/url.const';
import { useUserStore } from '@/stores/user.store';
import { redirectTo } from '@/utils/common.util';
import { isLogin } from '@/utils/user.utils';

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  const { query, params } = to;
  const router = useRouter();
  const route = useRoute();
  const userStore = useUserStore();
  const { selectedGroupId, isMember } = storeToRefs(userStore);
  const isUserLogin = isLogin();

  if (isUserLogin && !Object.keys(query).length && isMember.value) {
    const cookieLocale = useCookieWithDomain(COOKIE_KEYS.LOCALE || '');
    const finalLocale = getFinialLocale(route.fullPath);
    cookieLocale.value = finalLocale.toUpperCase();
    if (!selectedGroupId.value) {
      return await router.push(`/${finalLocale}${GROUP_REGISTER_PAGE_URL}`);
    }
    if (!params.to || params.to === 'group-home') {
      return await redirectTo(GROUP_HOME_PAGE_NAME, {
        toType: 'name',
        groupId: selectedGroupId.value
      });
    }
  }
});
